import { faGithub, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faFileWord } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Hidden, Link, Typography, withStyles } from '@material-ui/core';
import profileImg from 'assets/img/Profile Picture.jpg';
import * as React from 'react';
import { styles } from './Welcome.style';

interface WelcomeProps {
  classes: Record<string, any>;
}

interface WelcomeState {}

export class WelcomeComp extends React.Component<WelcomeProps, WelcomeState> {
  state: WelcomeState = {};

  componentDidMount = () => {};

  render = () => {
    const { classes } = this.props;

    const links = {
      linkedin: 'https://www.linkedin.com/in/zurakowskijakub/',
      github: 'https://github.com/ZurakowskiJakub/',
      email: 'mailto:zurakowski.jakub@outlook.com',
      download: undefined,
    };

    const yearsOld = Math.abs(
      new Date(Date.now() - new Date(1996, 9, 26).getTime()).getUTCFullYear() -
        1970
    );

    const placeOfWork = 'Bank of America';

    return (
      <>
        <div className={classes.filler}></div>

        <Grid container>
          <Grid container spacing={4}>
            <Grid container item xs={12} md={3} justify='center'>
              <img
                src={profileImg}
                alt='Jakub Żurakowski'
                className={classes.img}
              />
            </Grid>
            <Grid
              container
              item
              direction='column'
              justify='center'
              xs={12}
              md={9}
            >
              <Typography variant='h2' className={classes.textAlignCenter}>
                Jakub Żurakowski
              </Typography>
              <Typography
                variant='h4'
                className={`${classes.marginLeft} ${classes.textAlignCenter}`}
              >
                Software Engineer
              </Typography>
              <Typography
                variant='h4'
                className={`${classes.marginLeft} ${classes.textAlignCenter}`}
              >
                Bank of America
              </Typography>
            </Grid>
          </Grid>

          <Grid container className={classes.marginTop}>
            <Grid container item xs={3} direction='column' alignItems='center'>
              <Link
                className={`${classes.alignCenter} ${classes.link}`}
                href={links?.linkedin}
                target='_blank'
              >
                <FontAwesomeIcon icon={faLinkedin} size='3x' />
                <Typography variant='subtitle2'>LinkedIn</Typography>
              </Link>
            </Grid>

            <Grid container item xs={3} direction='column' alignItems='center'>
              <Link
                className={`${classes.alignCenter} ${classes.link}`}
                href={links?.github}
                target='_blank'
              >
                <FontAwesomeIcon icon={faGithub} size='3x' />
                <Typography variant='subtitle2'>Github</Typography>
              </Link>
            </Grid>

            <Grid container item xs={3} direction='column' alignItems='center'>
              <Link
                className={`${classes.alignCenter} ${classes.link}`}
                href={links?.email}
                target='_blank'
              >
                <FontAwesomeIcon icon={faEnvelope} size='3x' />
                <Typography variant='subtitle2'>Email</Typography>
              </Link>
            </Grid>

            <Grid container item xs={3} direction='column' alignItems='center'>
              <Link
                className={`${classes.alignCenter} ${classes.link} ${classes.disabled}`}
                href={links?.download}
                target='_blank'
              >
                <FontAwesomeIcon icon={faFileWord} size='3x' />
                <Typography variant='subtitle2'>C.V</Typography>
              </Link>
            </Grid>
          </Grid>
        </Grid>

        <Hidden smDown>
          <Typography
            className={`${classes.marginTop} ${classes.blockQuote}`}
            variant='body1'
          >
            I always said, that in life only one thing truly matters - that you
            are happy. We should always strive to be happy and do what we really
            want to do. Start and end each day with a smile, sharing it with
            everyone we meet.
          </Typography>
          <Typography className={classes.blockQuote} variant='body1'>
            I do what I like for a living, I write code. Whether this is at
            work, looking to make {placeOfWork} a better place, or at home,
            working on small projects with colleagues, trying to create the next
            big thing.
          </Typography>
          <Typography className={classes.blockQuote} variant='body1'>
            Let's all do our best, to be as happy as we can be.
          </Typography>
        </Hidden>
      </>
    );
  };
}

export const Welcome = withStyles(styles, { withTheme: true })(WelcomeComp);
