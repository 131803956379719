import { Container, CssBaseline, withStyles } from '@material-ui/core';
import { FullPage } from 'components/common/FullPage/FullPage';
import { Experiences } from 'components/Experiences/Experiences';
import { Welcome } from 'components/Welcome/Welcome';
import * as React from 'react';
import { styles } from './App.style';

interface AppProps {
  classes: Record<string, any>;
}

interface AppState {}

export class AppComp extends React.Component<AppProps, AppState> {
  state: AppState = {};

  componentWillMount = () => {};

  render = () => {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <Container>
          <FullPage>
            <Welcome />
          </FullPage>
          <FullPage>
            <Experiences />
          </FullPage>
        </Container>
      </div>
    );
  };
}

export const App = withStyles(styles, { withTheme: true })(AppComp);
