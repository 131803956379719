import { Theme, createStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

type CSSProp = {
  [key: string]: CSSProperties;
};

type MediaQ = {
  [key: string]: {
    [key: string]: CSSProperties;
  };
};

export const styles = createStyles((theme: Theme) => {
  const css: CSSProp | MediaQ = {
    filler: {
      display: 'block',
      height: '10vh',
    },

    img: {
      width: '100%',
      maxWidth: '45vw',
      borderRadius: '50%',
    },

    marginTop: {
      marginTop: '3em',
    },

    alignCenter: {
      textAlign: 'center',
    },

    link: {
      color: theme.palette.primary.main,
    },

    blockQuote: {
      borderLeft: `.5vw solid ${theme.palette.primary.light}`,
      paddingLeft: `.5vw`,
      marginLeft: '10vw',
      marginRight: '10vw',
      backgroundColor: theme.palette.background.default,
    },

    marginLeft: {
      [theme.breakpoints.up('md')]: {
        marginLeft: '1.5em',
      },
    },

    textAlignCenter: {
      [theme.breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },

    disabled: {
      color: theme.palette.action.disabled,
      '&:hover': {
        textDecoration: 'none',
      },
    },
  };
  return css;
});
