import { MuiThemeProvider } from '@material-ui/core/styles';
import { Redirect } from 'components/common/Redirect/Redirect';
import * as React from 'react';
import { HashRouter, Route, Routes } from 'react-router-dom';
import { App } from './App';
import { getTheme } from './theme';

const CUSTOM_THEME = getTheme();

interface RootProps {}

interface RootState {}

export class Root extends React.Component<RootProps, RootState> {
  state: RootState = {};

  componentDidMount = () => {};

  render = () => {
    return (
      <HashRouter>
        <Routes>
          <Route path='/bbq' element={<Redirect />} />
          <Route
            path='/'
            element={
              <MuiThemeProvider theme={CUSTOM_THEME}>
                <App />
              </MuiThemeProvider>
            }
          />
        </Routes>
      </HashRouter>
    );
  };
}
