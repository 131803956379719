import { withStyles } from '@material-ui/core';
import * as React from 'react';
import { styles } from './FullPage.style';

interface FullPageProps {
  classes: Record<string, any>;
  children: React.ReactElement;
}

interface FullPageState {}

export class FullPageComp extends React.Component<
  FullPageProps,
  FullPageState
> {
  state: FullPageState = {};

  componentDidMount = () => {};

  render = () => {
    const { classes, children } = this.props;

    return <div className={classes.fullpage}>{children}</div>;
  };
}

export const FullPage = withStyles(styles, { withTheme: true })(FullPageComp);
