import { Theme, createStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

type CSSProp = {
  [key: string]: CSSProperties;
};

type MediaQ = {
  [key: string]: {
    [key: string]: CSSProperties;
  };
};

export const styles = createStyles((theme: Theme) => {
  const css: CSSProp | MediaQ = {
    fullpage: {
      minHeight: '100vh',
    },
  };
  return css;
});
