import { Theme, createStyles } from '@material-ui/core/styles';
import { CSSProperties } from '@material-ui/core/styles/withStyles';

type CSSProp = {
  [key: string]: CSSProperties;
};

type MediaQ = {
  [key: string]: {
    [key: string]: CSSProperties;
  };
};

export const styles = createStyles((theme: Theme) => {
  const css: CSSProp | MediaQ = {
    boxMain: {
      backgroundColor: theme.palette.background.default,
    },

    expHeading: {
      backgroundColor: theme.palette.primary.light,
      fontWeight: 'bold',
      marginTop: '1.5em',
      padding: '.5em',
    },

    marginTop: {
      marginTop: '1.5em',
    },

    iconBox: {
      color: theme.palette.secondary.main,
    },

    iconSize: {
      maxWidth: 50,
    },
  };
  return css;
});
