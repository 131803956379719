import {
  createMuiTheme,
  responsiveFontSizes,
  Theme,
  ThemeOptions,
} from '@material-ui/core';
import LatoBlack from 'assets/fonts/LATO-BLACK.woff2';
import LatoBlackItalic from 'assets/fonts/LATO-BLACKITALIC.woff2';
import LatoBold from 'assets/fonts/LATO-BOLD.woff2';
import LatoBoldItalic from 'assets/fonts/LATO-BOLDITALIC.woff2';
import LatoItalic from 'assets/fonts/LATO-ITALIC.woff2';
import LatoLight from 'assets/fonts/LATO-LIGHT.woff2';
import LatoLightItalic from 'assets/fonts/LATO-LIGHTITALIC.woff2';
import LatoRegular from 'assets/fonts/LATO-REGULAR.woff2';

type FontFace = {
  fontFamily: string;
  fontStyle: string;
  fontDisplay: 'swap' | 'auto' | 'block' | 'fallback' | 'optional' | undefined;
  fontWeight: number;
  src: string;
};

const latoBlack: FontFace = {
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 1000,
  src: `
    local('Lato'),
    local('Lato-Black'),
    url(${LatoBlack}) format('woff2')
  `,
};
const latoBlackItalic: FontFace = {
  fontFamily: 'Lato',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 1000,
  src: `
    local('Lato'),
    local('Lato-Black-Italic'),
    url(${LatoBlackItalic}) format('woff2')
  `,
};
const latoBold: FontFace = {
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 800,
  src: `
    local('Lato'),
    local('Lato-Bold'),
    url(${LatoBold}) format('woff2')
  `,
};
const latoBoldItalic: FontFace = {
  fontFamily: 'Lato',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 800,
  src: `
    local('Lato'),
    local('Lato-Bold-Italic'),
    url(${LatoBoldItalic}) format('woff2')
  `,
};
const latoItalic: FontFace = {
  fontFamily: 'Lato',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Lato'),
    local('Lato-Italic'),
    url(${LatoItalic}) format('woff2')
  `,
};
const latoLight: FontFace = {
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 100,
  src: `
    local('Lato'),
    local('Lato-Light'),
    url(${LatoLight}) format('woff2')
  `,
};
const latoLightItalic: FontFace = {
  fontFamily: 'Lato',
  fontStyle: 'italic',
  fontDisplay: 'swap',
  fontWeight: 100,
  src: `
    local('Lato'),
    local('Lato-Light-Italic'),
    url(${LatoLightItalic}) format('woff2')
  `,
};
const latoReg: FontFace = {
  fontFamily: 'Lato',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Lato'),
    local('Lato-Regular'),
    url(${LatoRegular}) format('woff2')
  `,
};

export const getTheme = (dynamicOptions: ThemeOptions = {}): Theme => {
  const options: ThemeOptions = {
    palette: {
      primary: {
        light: '#E3F2FD',
        main: '#2589BD',
        dark: '#102B3F',
      },
      secondary: {
        light: '#E0E9C3',
        main: '#A8C256',
        dark: '#676F54',
      },
    },
    typography: {
      fontFamily: ['Lato', 'sans-serif'].join(','),
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': [
            latoBlack,
            latoBlackItalic,
            latoBold,
            latoBoldItalic,
            latoItalic,
            latoLight,
            latoLightItalic,
            latoReg,
          ],
        },
      },
    },
    ...dynamicOptions,
  };

  // Used to generate responsive theme.
  let theme = createMuiTheme(options);
  theme = responsiveFontSizes(theme);

  return theme;
};
