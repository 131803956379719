import React from 'react';

export class Redirect extends React.Component {
  componentDidMount(): void {
    window.location.href =
      'https://docs.google.com/document/d/1Z1DqX9Rqs4NpGKbwmjIpghzLhvEgpuJ734EPJlSNrvA/edit?usp=drive_link';
  }

  render(): React.ReactNode {
    return 'redirecting...';
  }
}
