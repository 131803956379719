import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import icon_photoshop from 'assets/img/skills/icons8-adobe-photoshop-100.png';
import icon_ansible from 'assets/img/skills/icons8-ansible-100.png';
import icon_bitbucket from 'assets/img/skills/icons8-bitbucket-100.png';
import icon_c from 'assets/img/skills/icons8-c++-100.png';
import icon_css from 'assets/img/skills/icons8-css3-100.png';
import icon_git from 'assets/img/skills/icons8-git-100.png';
import icon_github from 'assets/img/skills/icons8-github-100.png';
import icon_html from 'assets/img/skills/icons8-html-5-100.png';
import icon_java from 'assets/img/skills/icons8-java-100.png';
import icon_jenkins from 'assets/img/skills/icons8-jenkins-100.png';
import icon_office from 'assets/img/skills/icons8-office-365-100.png';
import icon_python from 'assets/img/skills/icons8-python-100.png';
import icon_react from 'assets/img/skills/icons8-react-native-100.png';
import icon_typescript from 'assets/img/skills/icons8-typescript-100.png';
import * as React from 'react';
import { styles } from './Experiences.style';

type SkillType = 'BE' | 'FE' | 'DevOps' | 'Personal';

type Skill = {
  image: string;
  displayName: string;
  tags?: Set<SkillType>;
};

const skills: Array<Skill> = [
  { image: icon_python, displayName: 'Python' },
  { image: icon_java, displayName: 'Java' },
  { image: icon_c, displayName: 'C++' },
  { image: icon_react, displayName: 'React' },
  { image: icon_html, displayName: 'HTML5' },
  { image: icon_css, displayName: 'CSS3' },
  { image: icon_typescript, displayName: 'TypeScript (ES6)' },
  { image: icon_jenkins, displayName: 'Jenkins' },
  { image: icon_ansible, displayName: 'Ansible Tower' },
  { image: icon_git, displayName: 'Git' },
  { image: icon_github, displayName: 'GitHub' },
  { image: icon_bitbucket, displayName: 'BitBucket' },
  { image: icon_photoshop, displayName: 'Photoshop' },
  { image: icon_office, displayName: 'MS Office Suite' },
];

interface ExperiencesProps {
  classes: Record<string, any>;
}

interface ExperiencesState {
  skillsSearchValue: string;
  tooltipOpen: string | null;
}

export class ExperiencesComp extends React.Component<
  ExperiencesProps,
  ExperiencesState
> {
  state: ExperiencesState = {
    skillsSearchValue: '',
    tooltipOpen: null,
  };

  componentDidMount = () => {};

  onSkillsSearchValueChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      skillsSearchValue: event.target.value,
    });
  };

  toggleToolTip = (tooltipName: string | null) => {
    if (tooltipName) {
      this.setState({ tooltipOpen: tooltipName });
    } else {
      this.setState({ tooltipOpen: null });
    }
  };

  handleTooltipClose = (event: React.ChangeEvent<{}>) => {
    console.log(event.target);
  };

  // handleTooltipOpen = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  handleTooltipOpen = (displayName: string) => {
    this.setState({ tooltipOpen: displayName });
  };

  render = () => {
    const { classes } = this.props;
    const { skillsSearchValue, tooltipOpen } = this.state;

    return (
      <>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Typography variant='h2'>My journey so far...</Typography>
          </Grid>

          <Grid
            container
            item
            className={classes.boxMain}
            xs={12}
            md={8}
            direction='column'
          >
            <Typography variant='h4'>Experience</Typography>
            <Grid container item>
              <Grid container>
                <Grid container item className={classes.expHeading}>
                  <Grid item xs={12} md={9}>
                    <Typography variant='h5'>
                      Full Stack Software Engineer @ Bank of America
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography align='right'>Jul. 2019 - Current</Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1' paragraph align='justify'>
                    I joined Bank of America as a Graduate on their 2 year
                    Graduate programme. I was lucky enough to have previously
                    done an internship at the bank, joining back to the same
                    team.
                  </Typography>
                  <Typography variant='body1' paragraph align='justify'>
                    Straight away, I began working as a Full Stack developer,
                    working on front-end using React and back-end using Java.
                    Our team is agile, following 2 week sprints and all other
                    agile routines. We take a lot of pride in achieving 'Leap'
                    status this year.
                  </Typography>
                  <Typography variant='body1' paragraph align='justify'>
                    Throughout my career at Bank of America I have worked with
                    nearly every popular technology, including Java, Python and
                    C# . Throughout the months my responsibilities grew more and
                    more, eventually becoming the primary developer for one of
                    the high-visability applications that my team was
                    responsible for.
                  </Typography>
                </Grid>
              </Grid>

              <Grid container>
                <Grid container item className={classes.expHeading}>
                  <Grid item xs={12} md={9}>
                    <Typography variant='h5'>
                      Intern @ Bank of America
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Typography align='right'>
                      Mar. 2018 - Sept. 2018
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='body1' paragraph align='justify'>
                    6 month internship as part of college work-experience
                    module.
                  </Typography>
                  <Typography variant='body1' paragraph align='justify'>
                    The internship consisted of working with the agile team on
                    varioius projects, in various areas. I have experienced
                    working on front-end in JSP and Angular; working on back-end
                    in Java, Python and C#; and working with SQL and Sybase
                    databases.
                  </Typography>
                  <Typography variant='body1' paragraph align='justify'>
                    This was a very sucesfull internship, securing me a
                    full-time graduate position in Bank of America on completion
                    of my 4th Year Bachelors (Honours) Degree.
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            item
            className={classes.boxMain}
            xs={12}
            md={4}
            direction='column'
          >
            <Typography variant='h4'>Skills</Typography>
            <Grid container>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label='Search'
                  value={skillsSearchValue}
                  onChange={this.onSkillsSearchValueChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <FontAwesomeIcon icon={faFilter} />
                      </InputAdornment>
                    ),
                  }}
                />
                <Grid
                  container
                  spacing={2}
                  justify='center'
                  className={classes.marginTop}
                >
                  {skills
                    .filter((skill: Skill) =>
                      skill.displayName
                        .toLowerCase()
                        .includes(skillsSearchValue.toLowerCase())
                    )
                    .map((skill: Skill, index: number) => (
                      <Grid
                        key={index}
                        container
                        item
                        xs={3}
                        justify='center'
                        className={classes.iconBox}
                      >
                        <Tooltip
                          title={skill.displayName}
                          arrow
                          open={tooltipOpen === skill.displayName}
                          onClose={() => this.toggleToolTip(null)}
                        >
                          <Button
                            // name={skill.displayName}
                            onClick={() =>
                              this.handleTooltipOpen(skill.displayName)
                            }
                          >
                            <img
                              src={skill.image}
                              alt={skill.displayName}
                              className={classes.iconSize}
                            />
                          </Button>
                        </Tooltip>
                      </Grid>
                    ))}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };
}

export const Experiences = withStyles(styles, { withTheme: true })(
  ExperiencesComp
);
